import { createSelector } from 'reselect';

export const countriesSelector = state => state.address.list;
export const zipcitystateSelector = state => state.address.zipcitystate;
export const counytryStateSelector = state => state.address.countryState;
export const addressSelector = state => state.address.addressData;

export const getCountries = createSelector(
  countriesSelector,
  list => list,
);

export const getZipCityState = createSelector(
  zipcitystateSelector,
  zipcitystate => zipcitystate,
);

export const getCounytryState = createSelector(
  counytryStateSelector,
  countryState => countryState,
);

export const getAddressData = createSelector(
  addressSelector,
  addressData => addressData,
);

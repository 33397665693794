import { SET_COUNTRIES, UNSET_COUNTRIES, SET_ZIP_CODE_LIST, SET_STATE_DATA, SET_ADDRESS_DATA } from './types';

const initialState = {
  list: JSON.parse(localStorage.getItem('countries')),
  zipcitystate: [],
  countryState: [],
  addressData: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_COUNTRIES: {
      return { ...state, list: action.payload.countries };
    }
    case UNSET_COUNTRIES: {
      return action.payload.countries;
    }
    case SET_ZIP_CODE_LIST: {
      return { ...state, zipcitystate: action.payload.zipcitystate };
    }
    case SET_STATE_DATA: {
      return { ...state, countryState: action.payload.countryState };
    }
    case SET_ADDRESS_DATA: {
      return { ...state, addressData: action.payload.addressData };
    }
    default: return state;
  }
};

export default reducer;
